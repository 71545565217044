<template>
  <flixColorpicker :open="false" :value="color" :callback="function (value) { color = value; setSave() }"/>
</template>
<script>
export default {
  components: {},
  props: {
    ressource: Object
  },
  data () {
    return {
      color: this.ressource.color
    }
  },
  methods: {
    setSave () {
      this.ressource.color = this.color
      var send = {
        user: this.$store.getters.user.md5_id,
        ID: this.ressource.ID,
        data: this.ressource
      }
      this.$flix_post({ url: 'ressources/save', data: send, callback: function (ret) {} })
    }
  },
  mounted () {

  },
  computed: {

  }
}
</script>
<style lang="sass" scoped>
</style>
